/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
    --ion-color-primary: #00acc1;
    --ion-color-primary-rgb: 0, 172, 193;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #0097aa;
    --ion-color-primary-tint: #1ab4c7;

    --ion-color-secondary: #e57373;
    --ion-color-secondary-rgb: 229, 115, 115;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #ca6565;
    --ion-color-secondary-tint: #e88181;

    --ion-color-tertiary: #aaaaaa;
    --ion-color-tertiary-rgb: 170, 170, 170;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #969696;
    --ion-color-tertiary-tint: #b3b3b3;

    --ion-color-success: #14a34d;
    --ion-color-success-rgb: 20, 163, 77;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #128f44;
    --ion-color-success-tint: #2cac5f;

    --ion-color-warning: #da7a0b;
    --ion-color-warning-rgb: 218, 122, 11;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #c06b0a;
    --ion-color-warning-tint: #de8723;

    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    --ion-color-dark: #595A5B;
    --ion-color-dark-rgb: 89, 90, 91;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #4e4f50;
    --ion-color-dark-tint: #6a6b6b;

    --ion-color-medium: #B2B9C0;
    --ion-color-medium-rgb: 178, 185, 192;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #9da3a9;
    --ion-color-medium-tint: #bac0c6;

    --ion-color-light: #E9E9EC;
    --ion-color-light-rgb: 233, 233, 236;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #cdcdd0;
    --ion-color-light-tint: #ebebee;
    --ion-font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;



    --ion-background-color: #ffffff;
    --ion-background-color-rgb: 255, 255, 255;
    --ion-text-color: #616161;
    --ion-text-color-rgb: 97, 97, 97;
    --ion-color-step-50: #f7f7f7;
    --ion-color-step-100: #efefef;
    --ion-color-step-150: #e7e7e7;
    --ion-color-step-200: #dfdfdf;
    --ion-color-step-250: #d8d8d8;
    --ion-color-step-300: #d0d0d0;
    --ion-color-step-350: #c8c8c8;
    --ion-color-step-400: #c0c0c0;
    --ion-color-step-450: #b8b8b8;
    --ion-color-step-500: #b0b0b0;
    --ion-color-step-550: #a8a8a8;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #989898;
    --ion-color-step-700: #909090;
    --ion-color-step-750: #898989;
    --ion-color-step-800: #818181;
    --ion-color-step-850: #797979;
    --ion-color-step-900: #717171;
    --ion-color-step-950: #696969;
    --ion-grid-width-lg: 720px;
    --ion-grid-width-xl: 720px;
}

/* Set text color of the entire app for iOS only */
.ios {
    --ion-text-color: #616161;
    --ion-text-color-rgb: 97, 97, 97;
    --ion-background-color: #ffffff;
    --ion-background-color-rgb: 255, 255, 255;

}

/* Set text color of the entire app for Material Design only */
.md {
    --ion-text-color: #616161;
    --ion-text-color-rgb: 97, 97, 97;
    --ion-background-color: #ffffff;
    --ion-background-color-rgb: 255, 255, 255;
}